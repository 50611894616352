import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "analogue-imaginings",
      "style": {
        "position": "relative"
      }
    }}>{`Analogue Imaginings`}<a parentName="h1" {...{
        "href": "#analogue-imaginings",
        "aria-label": "analogue imaginings permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`In this session, Trent McConaghy (founder of Ocean Protocol) will join us to chat all things Decentralized AI, AI DAOs, AI in Art/Climate/DeFi, Tokenizing AI communities and more.`}</p>
    <h2 {...{
      "id": "preparation",
      "style": {
        "position": "relative"
      }
    }}>{`Preparation`}<a parentName="h2" {...{
        "href": "#preparation",
        "aria-label": "preparation permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Familiarise yourself with some of Trent's work:`}</p>
    <ol>
      <li parentName="ol">
        <Link to="https://blog.oceanprotocol.com/a-wholistic-overview-of-the-ocean-ecosystem-dbb3a803096b" mdxType="Link">A Wholistic Overview of the Ocean Ecosystem</Link>
      </li>
      <li parentName="ol">
        <Link to="https://blog.oceanprotocol.com/towards-a-practice-of-token-engineering-b02feeeff7ca?gi=d33bffc65708" mdxType="Link">Towards a Practice of Token Engineering</Link>
      </li>
      <li parentName="ol">
        <Link to="https://blog.oceanprotocol.com/the-web3-sustainability-loop-b2a4097a36e" mdxType="Link">The web3 Sustainability Loop</Link>
      </li>
    </ol>
    <h2 {...{
      "id": "recording",
      "style": {
        "position": "relative"
      }
    }}>{`Recording`}<a parentName="h2" {...{
        "href": "#recording",
        "aria-label": "recording permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <Video src="https://www.youtube-nocookie.com/embed/iwf3muttuqQ" mdxType="Video" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      